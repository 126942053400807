import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Firebase from '../../firebase'

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    //minHeight: 500,
    width: '100%',
  },
  media: {
    height: 400,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10
  }
});

export default function Upcoming() {
    const classes = useStyles();
    const [events, setEvents] = useState()

    useEffect(() => {
        Firebase.getUpcomingEvents()
        .then(pastEvents => {
            setEvents(pastEvents)
        })
    }, [])

    const handleClick = (url) => () => {
        window.location.assign(url);
    }
    return events === undefined ? 
            <Grid container>
                <Grid xs={12} item>
                    Events will be updated shortly.
                </Grid>
            </Grid>
            :
            <Grid container>
                <Grid item xs={12} sm={3}>

                </Grid>
                {
                events.map((event, index)=>(
                    index < 2 &&
                    <Grid item xs={12} sm={3} className={classes.center}>
                        <Card className={classes.card}>
                            <CardActionArea>
                                <CardMedia
                                className={classes.media}
                                image={event.image}
                                title="Contemplative Reptile"
                                />
                                {/* <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    <i>{event.title}</i>
                                </Typography>
                                <Typography>
                                    ~ {event.speaker}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {event.brief}
                                </Typography>
                                </CardContent> */}
                            </CardActionArea>
                            <CardActions className={classes.center}>
                                <Button onClick={handleClick(event.link)}  size="small" color="primary">
                                    Register
                                </Button>
                            </CardActions>
                        </Card>
                        </Grid>
                ))
                }
                <Grid item xs={12} sm={3}>
                    
                </Grid>
            </Grid>
                    
                    
  
}