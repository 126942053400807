import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from '@material-ui/core/Toolbar';
import Header from './header'
import InfoIcon from '@material-ui/icons/Info';
import BookIcon from '@material-ui/icons/Book';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

const pages = [
    {
        label: "Home", 
        url: "#home",
        icon: <InfoIcon/>
    },
    {
        label: "Events", 
        url: "#events",
        icon: <CalendarTodayIcon/>
    },
    {
        label: "Get Involved", 
        url: "#getinvolved",
        icon: <AddIcon/>
    },
    {
        label: "Blogs", 
        url: "#blogs",
        icon: <BookIcon/>
    },
    {
        label: "Contact Us", 
        url: "#contactus",
        icon: <EditIcon/>
    },
]

const useStyles = makeStyles((theme) => {
  return {
    root: {
        width: '100%',
        position: 'fixed',
        zIndex: 1100,
    },
    title: {
        //flexGrow: 1,
        color: 'black'
    },
    link: {
        color: 'white'
    },
    linkDrawer: {
        color: 'black'
    },
    list: {
        minWidth: '150px',
        
    },
    fullList: {
        
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    namelarge: {
        position: 'fixed',
        right: '0',
        color: 'white',
        paddingRight: '40px'
    },
  };
});


const Navigation = (props) => {

    const [state, setState] = React.useState({
        left: false
    });
    const classes = useStyles();
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
    };
    const dimensions = ({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    const list = (anchor) => (
        <div
          className={classes.fullList}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        > 
          <List>
                <ListItem>
                    &nbsp;
                </ListItem>
                <ListItem>
                    <b>"Quote Unquote"</b>
                </ListItem>
                <Divider/>
                <ListItem>
                    &nbsp;
                </ListItem>
            {pages.map((page, index) => (
                <div className={classes.list}>
                    <ListItem button key={index}>
                        <Link className={classes.linkDrawer} href={page.url} >
                            {page.label}
                        </Link>
                    </ListItem>
              </div>
            ))}
          </List>
        </div>
    );

    return (

        <Toolbar className={classes.root}>
            
            <Header >
                
                {
                    dimensions.width > 600 &&
                    
                    pages.map((page) => (
                        <IconButton edge="start" className={classes.menuButton}>
                            <Typography
                                edge="start"
                                key={page.label}
                                className={classes.title}
                                align={props.align || "center"}
                                >
                                <Link className={classes.link} href={page.url}>
                                    {page.label}
                                </Link>
                            </Typography>
                        </IconButton>
                    ))
                    
                }
                {
                     dimensions.width <= 600 &&
                     <React.Fragment key={"left"}>
                        <Button onClick={toggleDrawer("left", true)}><MenuIcon/></Button>
                        <SwipeableDrawer
                            anchor={"left"}
                            open={state["left"]}
                            onClose={toggleDrawer("left", false)}
                            onOpen={toggleDrawer("left", true)}
                        >
                            {list("left")}
                        </SwipeableDrawer>
                    </React.Fragment>
                }
                {
                    dimensions.width > 600 && 
                    <Typography className={classes.namelarge}>
                        <b>"Quote Unquote"</b>
                    </Typography>
                }
                
            </Header>

            </Toolbar>
            
        
    );
};

export default Navigation;