import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { yellow, headingSize } from '../../constants'


const useStyles = makeStyles({
    cardOuter:{
        display: 'flex',
        justifyContent: 'center',
        padding: 10
    },  
    card: {
        minWidth: 275,
        maxWidth: 550,
        backgroundColor: yellow,
        
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    heading: {
        fontSize: headingSize,
    },
    headingOuter: {
        paddingTop: '5vh',
        paddingBottom: '5vh'
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 20,
        paddingRight: 20
    }
});
const whoAreWe = "In today’s fast-paced ever-changing world, braving all the curveballs relentlessly hurled at us every step of the way, we leave so many things unsaid and thoughts unexpressed. Despite being connected across platforms and mediums we often find ourselves unable to leverage our reach, for a multitude of reasons. The fear of backlash, judgement or misunderstanding can hold us back from putting our honest opinions and true selves out for all to see or merely even fronting a cause that we truly believe in. Understanding the need for the opportunity to express oneself unhindered to people from diverse geographical locations, ages, socioeconomic backgrounds who converge on the same ideas as us is what planted the seed for “Quote Unquote”. With an aim to facilitate conversations that provide nuanced perspectives and a wider worldview on extremely diverse themes, intending to continue to push the boundaries of our thoughts and beliefs."
const ourAim = "“Quote Unquote” is a community for like-minded individuals, a space where everyone can be genuine and raw, being certain that their opinions will be received without judgment accompanied by healthy and stimulating discussions. We aim to provide a platform for you to share your story, your ideas, your perspective on things that you are most passionate about. The sessions will be held virtually on a digital platform and the relevant information such as the date, time and topic regarding the same will be available on our social calendar. You can view the upcoming sessions and decide to register for the topics of your choice as a speaker or a participant."

function About() {
    const classes = useStyles();

 
    return (
        <Grid container >
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>Who are we?</i></b></div>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
                {whoAreWe}
            </Grid>
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>Our Aim</i></b></div>
            </Grid>
            <Grid item xs={12} style={{paddingBottom: '10vh'}} className={classes.gridItem}>
                {ourAim}
            </Grid>
        </Grid>
    
    
  );
}

export default About;

