import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { headingSize } from '../constants'
import Firebase from '../firebase'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme)=>({
    root:{
        padding: 10
    },
    card: {
        width: '100%'
        // maxWidth: 400,
        // minWidth: 300
    },
    media: {
        height: 140,
    },
    cardOuter: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10
    },
    cardAction: {
        display: 'flex',
        justifyContent: 'center'
    },
    heading: {
        fontSize: headingSize,
    },
    headingOuter: {
        width: '100%',
        textAlign: 'center',
        padding: 20
    },
    
    blogHeading: {
        display: 'flex',
        justifyContent: 'center'
    },

}));



function Blogs() {
    const classes = useStyles();
    const [blogs, setBlogs] = useState()
    const [author, setAuthor] = useState()
    const [title, setTitle] = useState()
    const [content, setContent] = useState()

    useEffect(() => {
        Firebase.getBlogs()
        .then(blogs => {
            setBlogs(blogs)
        })
    }, [])

    const [open, setOpen] = useState(false);
    const scroll = 'paper';

    const handleClickOpen = (blog) => () => {
        setAuthor(blog.author)
        setContent(blog.content)
        setTitle(blog.title)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    useEffect(() => {
        if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
            descriptionElement.focus();
        }
        }
    }, [open]);

    
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>Blogs</i></b></div>
            </Grid>
            {
                blogs === undefined ? 
                <Grid className={classes.cardOuter} item xs={12} >
                    Blogs coming soon!
                </Grid> 
                :
                blogs.map((blog, index) => (
                <Grid key={blog.title} className={classes.cardOuter} item xs={12} sm={4}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <CardMedia
                            className={classes.media}
                            image={blog.image}
                            title="Contemplative Reptile"
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                <b>{blog.title}</b>
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {blog.author}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <b>{blog.brief}</b>
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions className={classes.cardAction}>
                            <Button onClick={handleClickOpen(blog)}  size="small" color="primary">
                                Read More
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>))
            }
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title"> <i>{title}</i> <br/> by {author} </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    {content}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog> 
        </Grid>
  );
}

export default Blogs;