import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { blue } from '../constants'
//import Slide from '@material-ui/core/Slide';
import CssBaseline from '@material-ui/core/CssBaseline';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import PropTypes from 'prop-types';

const useStyles = makeStyles(() => {
  return {
    root: {
      //flexGrow: 1,
      
    },
    appbar: {
      backgroundColor: blue,
      color: 'white',
      position: 'absolute'
    },
    toolbar:{
      
    }
  };
});

// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({ target: window ? window() : undefined });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }
// HideOnScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };




const Header = (props) => {

    useEffect(() => {
    }, [])
    
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <CssBaseline />
        {/* <HideOnScroll {...props}> */}
        <AppBar className={classes.appbar}  >
            <Toolbar className={classes.toolbar}>
                {props.children}
            </Toolbar>
        </AppBar>
        {/* </HideOnScroll> */}
      </div>
        
    );
};

export default Header;

// #D9C3BD