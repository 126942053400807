export const green = '#899458' //green

export const yellow = '#d6ab4c' //yellow

export const blue = '#01999e' //blue

export const pink = '#f7d0cb' //pink

export const white = '#ebe2d3' //off white

export const red = '#d66c52' //red

export const headingSize = 45