import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { blue, headingSize } from '../constants'
import Form from './contactus/form'
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: blue,
        color: 'white'
    },
    heading: {
        fontSize: headingSize-20,
    },
    headingOuter: {
        width: '100%',
        textAlign: 'center',
        padding: 20
    },
    center: {
        textAlign: 'center',
        width: '100%'
        // display: 'flex',
        // justifyContent: 'center'
    },
    icon: {
        cursor: 'pointer'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    
}));

function ContactUs() {
    const classes = useStyles();
    
    const handleClickInstagram = () => () => {
        window.location.assign("https://www.instagram.com/__quoteunquote__/");
    }

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>Contact Us</i></b></div>
            </Grid>
            <Grid item xs={12}>
                <InstagramIcon className={classes.icon} onClick={handleClickInstagram()} fontSize="large"/>
                
                <MailOutlineIcon onClick={handleOpen} className={classes.icon}  fontSize="large"/>
                
            </Grid>
            <Grid item xs={12}>
                <Form/>
            </Grid>
            <Grid item xs={12}>
                <p>&copy; 2020 Harsh Nagra (Website) Quote Unquote (Content)</p>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.paper}>
                    <h2 className={classes.center} id="transition-modal-title"><b><i>Our Email</i></b></h2>
                    <p id="transition-modal-description"><a href="mailto:contact.qunq@gmail.com">contact.qunq@gmail.com</a> </p>
                </div>
                </Fade>
            </Modal>
        </Grid>
  );
}

export default ContactUs;