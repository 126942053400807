import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { headingSize } from '../constants'
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import PeopleIcon from '@material-ui/icons/People';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import CreateIcon from '@material-ui/icons/Create';
const useStyles = makeStyles({
    root: {
        //flexGrow: 1,
    },
    heading: {
        fontSize: headingSize,
    },
    headingOuter: {
        width: '100%',
        textAlign: 'center',
        padding: 20
    },
    center: {

        // display: 'flex',
        // justifyContent: 'center',
        padding: 10
    },
    subheading: {
        fontSize: headingSize-10,
    },
    link: {
        color: 'black',
        fontSize: 15
    }
});

function GetInvolved() {
    const classes = useStyles();
    const handleClick = () => () => {
        window.location.assign("https://forms.gle/j9bng51kSBBdPJfQ8");
    }
    
    return (
    <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>How can you get involved?</i></b></div>
                <div className={classes.center}>“Quote Unquote” will have bi-monthly sessions which will be listed on our social calendar ahead of time along with the pre-decided topic for discussion for that particular session. </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className={classes.headingOuter}><b className={classes.subheading}><i>Speaker</i></b></div>
                <div className={classes.center}>
                    As a speaker, this is an opportunity for you to lead the conversation and express your thoughts, perspectives and ideas with a group of like-minded individuals who are eager to hear from you and share the same interests as you.
                    <br/>
                </div>
                <div className={classes.center}>
                    <SettingsVoiceIcon fontSize="large"/>
                </div>
                <div className={classes.center}>
                    You will get fifteen minutes of uninterrupted speaking time which you can utilise in any manner you like. It could be in the form of a presentation, a poem or just you talking your heart out. While registering as the speaker, you will be required to submit a theme or a definite topic that you want to bring to the fore. If your topic and outline aligns with our community, we’d love for it to feature on our blog as well! 
                    <br/>
                    You could register as a speaker here or write to us at <b>contact.qunq@gmail.com</b>
                </div>
                <Button onClick={handleClick()} variant="contained">
                    Register
                </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className={classes.headingOuter}><b className={classes.subheading}><i>Participant</i></b></div>
                <div className={classes.center}>
                    As a participant, you can attend sessions on  topics that resonate with you, giving you the opportunity  to amalgamate other’s experiences and perspectives with your own. 
                    <br/>
                </div>
                <div className={classes.center}>
                    <PeopleIcon fontSize="large"/>
                </div>
                <div className={classes.center}>
                    While your level of participation completely depends on you, we would love for you to partake actively in the discussion that will follow suit thereby enriching the experience furthermore. 
                    <br/>
                    You can view the social calendar for coming sessions here. 
                </div>
                <Button variant="contained">
                    <Typography
                        className={classes.title}
                        >
                        <Link className={classes.link} href="#events">
                            View
                        </Link>
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={6}>
                <div className={classes.headingOuter}><b className={classes.subheading}><i>Write for us</i></b></div>
                
                <div className={classes.center}>
                    <CreateIcon fontSize="large"/>
                </div>
                <div className={classes.center}>
                    We are here to facilitate the exchange of meaningful and diverse conversations  while engaging with community members.  Each one of you is on a unique journey and your thoughts, ideas and experiences can prove to be valuable for other community members and challenge existing beliefs and structures. If you seek to express yourself, reach out to the community or make a difference, submit your thoughts in the form of a blog post / journal entry, article or opinion piece.  
                    <br/>
                    For further guidelines please click here. If you have any other questions or want to submit  your piece, write to us at <b>contact.qunq@gmail.com </b>
                </div>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
      </Grid>
    </div>
  );
}

export default GetInvolved;