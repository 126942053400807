import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from './images/logo.png';
import Grid from '@material-ui/core/Grid';
import Typing from 'react-typing-animation';
import { blue } from '../constants';


const useStyles = makeStyles({
    
    imageLargeScreen: {
        maxHeight: '60vh',
        maxWidth: '60vh',
        minHeight: '20vh',
        minWidth: '20vh'
    },
    imageSmallScreen: {
        maxHeight: '45vh',
        maxWidth: '45vh',
        minHeight: '20vh',
        minWidth: '20vh'
    },
    logo: {
        paddingTop: '10vh',
       
    },
    introduction: {
        
    },
    grid:{
        
    },
    textLargeScreen: {
        fontSize: '27px',
        paddingTop: '32vh'
    },
    textSmallScreen: {
        fontSize: '23px'
    },
    text: {
        color: blue
    }
});

function LandingPage() {
    const classes = useStyles();
    const dimensions = ({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    const text = () => (
        <div>
            <b> 
                Quote Unquote is a 
                <Typing>
                    <p className={classes.text}> 
                        Community<Typing.Backspace count={9} delay={500}/>Safe Space<Typing.Backspace count={10} delay={500}/>Platform<Typing.Backspace count={8} delay={500}/> 
                        Community<Typing.Backspace count={9} delay={500}/>Safe Space<Typing.Backspace count={10} delay={500}/>Platform<Typing.Backspace count={8} delay={500}/> 
                        Community<Typing.Backspace count={9} delay={500}/>Safe Space<Typing.Backspace count={10} delay={500}/>Platform<Typing.Backspace count={8} delay={500}/> 
                        Community<Typing.Backspace count={9} delay={500}/>Safe Space<Typing.Backspace count={10} delay={500}/>Platform<Typing.Backspace count={8} delay={500}/>
                        Community<Typing.Backspace count={9} delay={500}/>Safe Space.
                    </p>
                </Typing>
            </b>
        </div>
    )
    
    return dimensions.width > 600 ? (
        <Grid container className={classes.grid}>
            <Grid className={classes.logo} item xs={12} sm={6} >
                <img className={classes.imageLargeScreen} alt="logo" src={logo}/> 
            </Grid>
            
            <Grid className={classes.introduction} item xs={12} sm={6} >
                <div className={classes.textLargeScreen}>
                    {text()}
                </div>
            </Grid>
        </Grid>
        ) : (
        <Grid container className={classes.grid}>    
            <Grid className={classes.logo} item xs={12} >
                <img className={classes.imageSmallScreen} alt="logo" src={logo}/> 
            </Grid>
            
            <Grid className={classes.introduction} item xs={12} >
                <div className={classes.textSmallScreen}>
                    {text()}
                </div>
            </Grid>
        </Grid>
    )
                

}

export default LandingPage;
