import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import About from './home/about'
//import SafeSpace from './home/safeSpace'
import SafeSpaceOld from './home/safeSpace-old'
import Divider from '@material-ui/core/Divider';
const useStyles = makeStyles({
    root: {
    },
});

function Home() {
    const classes = useStyles();
    
    
    return (
    <div className={classes.root}>
        <Grid container >
            <Grid item xs={12}>
               <About/> 
            </Grid>
            <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
              <Divider style={{width: '80%'}}/>
            </Grid>
            {/* <Grid item xs={12}>
               <SafeSpace/> 
            </Grid> */}
            <Grid item xs={12}>
               <SafeSpaceOld/> 
            </Grid>
      </Grid>
    </div>
  );
}

export default Home;