import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Firebase from '../../firebase'
const useStyles = makeStyles((theme) => ({
    outerList: {
        display: 'flex',
        justifyContent: 'center'
    },
    list: {
        width: '100%',
        maxWidth: '100ch',
        //backgroundColor: 'white',
    },
    inline: {
        display: 'inline',
    },
    center: {
        textAlign: 'center',
        width: '100%'
    }
}));

export default function AlignItemsList() {
    const classes = useStyles()
    const [events, setEvents] = useState()
    useEffect(() => {
        Firebase.getPastEvents()
        .then(pastEvents => {
            setEvents(pastEvents)
        })
    }, [])

    return (
        <Grid container>
            <Grid className={classes.outerList} item xs={12}>
                <List className={classes.list}>
                    {
                        events === undefined ? <Grid item xs={12}>Blogs coming soon!</Grid> :
                        events.map((event, index) => (
                            <div>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <div className={classes.center}><b><i>{event.title}</i></b></div>
                                    }
                                    secondary={
                                    <React.Fragment>
                                    <Typography
                                    
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        <div className={classes.center}>Speaker: {event.speaker}</div>
                                    </Typography>
                                    <br/>
                                    <div className={classes.center}>{event.brief}</div>
                                    </React.Fragment>
                                }
                                />
                                
                            </ListItem>
                            { index+1 < events.length &&  <Divider  component="li" /> }
                            </div>
                        ))
                    }
                </List>
            </Grid>

        </Grid>
        
    );
}
