import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { headingSize } from '../../constants'


const useStyles = makeStyles({
    root: {
    },
    aboutus: {
        padding:'30px',
        // justifyContent:'center',
        // alignItems: 'center'
    },
    headingOuter: {
        //width: '100%',
        // /textAlign: 'center'
    },
    heading: {
        fontSize: headingSize
    },
    appbar: {
        // width: '100%',
        //backgroundColor: white,
        
        
    },
    gridappbar: {
        //width: '100%',
        padding: 10,
        
    },
    appbarouter: {
        // width: '100%',
    },
    tab: {
        // color: 'black',
        // zIndex: 500
    },
    typograh: {
        textAlign:'center', 
        
        //width:'100%'
    },
    bullet: {
        //display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    
});

function AboutUs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const bull = <span className={classes.bullet}>•</span>;
    
    return (
    <div className={classes.root}>
        <Grid container >
            <Grid className={classes.aboutus} item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>How will we ensure its a safe space?</i></b></div>
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
            <Grid className={classes.gridappbar}  item xs={12} sm={10}>
                <div className={classes.appbarouter}>
                <AppBar className={classes.appbar} position="static" color="default">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    >
                    <Tab className={classes.tab} label="Community Guidelines" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Content comprising of the following will be removed" {...a11yProps(1)} />
                    <Tab className={classes.tab} label="Anonymity" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        While “Quote Unquote” is a platform where we encourage uninhibited sharing of one’s thoughts, ideas and beliefs, we expect everyone to abide by certain guidelines to ensure a safe and constructive experience for all the members of our community. 
                        Violation of these guidelines will bear consequences that may result in participants being barred from the community. 
                        <br/>
                        {bull} Be tolerant towards others’ viewpoints; respectfully disagree when opinions do not align. <br/>
                        {bull} Communicate with courtesy and respect. <br/>
                        {bull} Respect the privacy and personal information of other participants. 
                        
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                    {bull} Any form of harassment or hateful conduct such as discrimination, hate speech or bullying.
                    <br/>
                    {bull} Use of profanity. 
                    <br/>
                    {bull} Presentation of false information as facts. 
                    <br/>
                    {bull} Intellectual property theft or copyright violation. 
                    <br/>
                    {bull} Sharing sensitive or personal information about another individual.
                    <br/>
                    {bull} Impersonation of another individual. 
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        We understand that certain information may be sensitive or subject to speculation and personal harassment. Hence, we offer our participants the option to express themselves anonymously through blog posts, 
                        articles and opinion pieces. Participation in online sessions, however, will not be anonymous.
                    </TabPanel>
                </SwipeableViews>
                </div>
            </Grid>
            <Grid item xs={12} sm={1}>
            </Grid>
      </Grid>
    </div>
  );
}

export default AboutUs;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  