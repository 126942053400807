import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'
import 'firebase/storage'

const config = {
    apiKey: "AIzaSyDcgXDBEq3GRUeQk4nOVHm7hsRcKKPk1kI",
    authDomain: "quoteunquotewebsite.firebaseapp.com",
    databaseURL: "https://quoteunquotewebsite.firebaseio.com",
    projectId: "quoteunquotewebsite",
    storageBucket: "quoteunquotewebsite.appspot.com",
    messagingSenderId: "1027589304211",
    appId: "1:1027589304211:web:8851a2a0131e5263d40266",
    measurementId: "G-0NKFDJLK22"
}

class Firebase {

    constructor() {
        app.initializeApp(config)
        this.auth = app.auth()
        this.db = app.firestore()
        this.storage = app.storage()
    }

    async getBlogs() {
        const data = await this.db.collection(`blogs`).get()
        const blogs = data.docs.map(doc => doc.data());
        return blogs
    }

    async getPastEvents() {
        const data = await this.db.collection(`past_events`).get()
        const pastEvents = data.docs.map(doc => doc.data());
        return pastEvents
    }

    async getUpcomingEvents() {
        const data = await this.db.collection(`upcoming_events`).get()
        const upcomingEvents = data.docs.map(doc => doc.data());
        return upcomingEvents
    }

    

    contactUs(name, email, subject, message) {
        return this.db.doc(`contact_us/${email+subject}`).set({
            name,
            email,
            subject,
            message
        })
    }

}


export default new Firebase()