import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { headingSize } from '../constants'
import Past from './events/past'
import Upcoming from './events/upcoming'

const useStyles = makeStyles({
    root: {
        //flexGrow: 1,
    },
    heading: {
        fontSize: headingSize,
    },
    headingOuter: {
        width: '100%',
        textAlign: 'center',
        padding: 20
    },
    past: {
        display: 'flex',
        justifyContent: 'center'
    }
});

function Events() {
    const classes = useStyles();
    

    return (
    
        <Grid container>
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>Upcoming Events</i></b></div>
            </Grid>
            <Grid item xs={12} classname={classes.past}>
                <Upcoming/>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.headingOuter}><b className={classes.heading}><i>Past Events</i></b></div>
            </Grid>
            <Grid item xs={12} classname={classes.past}>
                <Past/>
            </Grid>
      </Grid>
    
  );
}

export default Events;