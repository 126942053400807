import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { blue,  headingSize } from '../../constants'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Firebase from '../../firebase'

const useStyles = makeStyles({
    root: {
        backgroundColor: blue,
        color: 'white'
    },
    heading: {
        fontSize: headingSize,
    },
    headingOuter: {
        width: '100%',
        textAlign: 'center',
        padding: 20
    },
    textfield: {
        width: '300px'
    },
    outerTextfield: {
        padding: 10
    },
    formOuter: {
        
    },
    button:{
        width: '150px'
    },
    
    
});

function Form() {
    const classes = useStyles();

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    async function handleSubmit(event) {
        event.preventDefault()
        try {
            await Firebase.contactUs(name, email, subject, message)
            alert("Sent!")
            setName("")
            setEmail("")
            setMessage("")
            setSubject("")
        }
        catch {
            alert("Try Again")
        }
    }

    return (
    
        <Grid container className={classes.root}>
            
            <Grid item xs={12} className={classes.formOuter}>
                <form className={classes.root} onSubmit={handleSubmit} autoComplete="off">
                    <Grid item xs={12} className={classes.outerTextfield}>
                        <TextField 
                        
                            required
                            id="outlined-basic" 
                            label="Name" 
                            variant="outlined" 
                            className={classes.textfield}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.outerTextfield}>
                        <TextField 
                            id="outlined-basic" 
                            required
                            label="Email" 
                            variant="outlined" 
                            className={classes.textfield}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.outerTextfield}>
                        <TextField 
                            id="outlined-basic" 
                            required
                            label="Subject" 
                            variant="outlined" 
                            className={classes.textfield}
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.outerTextfield}>
                        <TextField 
                            id="outlined-basic"
                            required 
                            label="Message" 
                            variant="outlined" 
                            className={classes.textfield}
                            multiline
                            rows={4}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.outerTextfield}>
                        <Button 
                            variant="outlined"
                            endIcon={<SendIcon/>}
                            className={classes.button}
                            type="submit"
                        >
                            Send
                        </Button>
                    </Grid>
                </form>
            </Grid>
      </Grid>
    
  );
}

export default Form;