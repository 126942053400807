import React from 'react';
import './App.css';
import LandingPage from './pages/landingpage'
import Navigation from './components/navigation'
import Home from './pages/home'
import Blogs from './pages/blogs'
import Events from './pages/events'
import GetInvolved from './pages/getinvolved'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ContactUs from './pages/contactus'



function App() {
  return (
    <Grid container className="App">
        <Navigation/>
          <Grid item xs={12} id={''}>
            <LandingPage/>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
            <Divider style={{width: '80%'}}/>
          </Grid>
          <Grid item xs={12} id={'home'}>
            <Home/>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
            <Divider style={{width: '80%'}}/>
          </Grid>
          <Grid item xs={12} id={'events'}>
            <Events/>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
            <Divider style={{width: '80%'}}/>
          </Grid>
          <Grid item xs={12} id={'getinvolved'}>
            <GetInvolved/>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
            <Divider style={{width: '80%'}}/>
          </Grid>
          <Grid item xs={12} id={'blogs'}>
            <Blogs/>
          </Grid>
          <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={12}>
            <Divider style={{width: '80%'}}/>
          </Grid>
          <Grid item xs={12} id={'contactus'}>
            <ContactUs/>
          </Grid>
    </Grid>
  );
}

export default App;

// #F3EEE2